<template>
	<span>
		<svg v-if="code ==='cancel'" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z" fill="#B01C23"/>
		</svg>

		<svg v-if="code ==='check'" width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z" fill="#006633"/>
		</svg>

		<svg v-if="code ==='pending'" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5 11.5C4.17 11.5 3.5 10.83 3.5 10C3.5 9.17 4.17 8.5 5 8.5C5.83 8.5 6.5 9.17 6.5 10C6.5 10.83 5.83 11.5 5 11.5ZM10 11.5C9.17 11.5 8.5 10.83 8.5 10C8.5 9.17 9.17 8.5 10 8.5C10.83 8.5 11.5 9.17 11.5 10C11.5 10.83 10.83 11.5 10 11.5ZM15 11.5C14.17 11.5 13.5 10.83 13.5 10C13.5 9.17 14.17 8.5 15 8.5C15.83 8.5 16.5 9.17 16.5 10C16.5 10.83 15.83 11.5 15 11.5Z" fill="#EE9714"/>
		</svg>

		<svg v-if="code ==='pickup-sm'" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.31672 17.9961C7.30479 17.9961 7.29286 17.9961 7.28094 17.9961C7.25559 17.917 7.18253 17.8813 7.12736 17.8291C5.86742 16.6571 4.69098 15.4046 3.61742 14.0597C2.58263 12.7669 1.65072 11.4071 0.981234 9.88176C0.422089 8.6084 0.168609 7.29478 0.399723 5.90363C0.957377 2.53534 3.88432 0.0542254 7.31821 0.0527344C7.31821 0.936929 7.31821 1.82112 7.31821 2.70532C7.11096 2.71427 6.9037 2.71874 6.69644 2.74707C3.84107 3.13474 2.12785 6.21675 3.34903 8.83504C4.12587 10.4991 5.48123 11.3415 7.31821 11.3997C7.31821 13.5885 7.31821 15.7759 7.31821 17.9648C7.31523 17.9752 7.31523 17.9856 7.31672 17.9961Z" fill="#47484F"/>
			<path d="M7.31836 2.7036C7.31836 1.8194 7.31836 0.935209 7.31836 0.0510139C8.25474 0.0435587 9.15981 0.21503 10.0127 0.59674C12.3358 1.636 13.7418 3.40887 14.21 5.9198C14.2488 6.12557 14.2354 6.33879 14.3099 6.5371C14.3099 6.6206 14.3099 6.7041 14.3099 6.78759C14.2503 6.95459 14.2518 7.1201 14.3099 7.2871C14.3099 7.35867 14.3099 7.43024 14.3099 7.50181C14.2726 7.51225 14.2756 7.54356 14.2741 7.57338C14.2547 7.83431 14.2055 8.08928 14.1459 8.34276C13.8596 9.57437 13.302 10.6867 12.6414 11.7513C11.5052 13.5823 10.1111 15.2076 8.60812 16.7404C8.19361 17.1638 7.77909 17.5903 7.31836 17.9645C7.31836 15.7757 7.31836 13.5883 7.31836 11.3994C7.5614 11.389 7.80295 11.3696 8.04301 11.3279C10.3363 10.9312 11.9063 8.80946 11.6036 6.49982C11.4292 5.16831 10.7642 4.1365 9.65037 3.39843C8.94659 2.93173 8.1608 2.71851 7.31836 2.7036Z" fill="#303033"/>
			<path d="M14.3098 7.28759C14.2516 7.12059 14.2502 6.95508 14.3098 6.78809C14.3098 6.95359 14.3098 7.12059 14.3098 7.28759Z" fill="#47484F"/>
			<path d="M7.31792 2.70312C8.16036 2.71804 8.94764 2.93126 9.65141 3.39796C10.7652 4.13603 11.4302 5.16784 11.6047 6.49935C11.9059 8.80899 10.3373 10.9308 8.04406 11.3274C7.804 11.3691 7.56245 11.3885 7.31941 11.3989C5.48243 11.3408 4.12557 10.4984 3.35022 8.83434C2.12905 6.21605 3.84227 3.13404 6.69913 2.74487C6.9034 2.71654 7.11066 2.71207 7.31792 2.70312ZM8.04555 3.61416C8.00976 3.62161 7.99784 3.62012 7.98889 3.62609C7.054 4.27917 6.11464 4.92479 5.1872 5.58831C4.81295 5.85521 4.6206 6.24587 4.61165 6.70511C4.59973 7.29408 4.84873 7.75631 5.36911 8.04259C5.90589 8.33782 6.44416 8.29458 6.95112 7.95909C7.26275 7.75332 7.56394 7.53116 7.8711 7.31495C7.93819 7.26724 7.99784 7.21804 8.04555 7.14348C8.11712 7.03016 8.23491 6.98394 8.3706 7.02569C8.51821 7.07042 8.5704 7.18523 8.5704 7.32688C8.5704 7.46257 8.49883 7.5565 8.36762 7.58334C8.26324 7.60422 8.18273 7.6579 8.10072 7.71605C7.71155 7.98891 7.3373 8.28861 6.92726 8.5242C6.57537 8.72698 6.43372 8.97301 6.47994 9.37708C6.51722 9.69617 6.4874 10.0227 6.4874 10.3522C6.52169 10.3418 6.53362 10.3418 6.54257 10.3358C7.47597 9.68126 8.41533 9.03414 9.33829 8.36466C9.76772 8.05303 9.96006 7.59527 9.90042 7.06893C9.76921 5.90143 8.54058 5.34975 7.56692 6.01028C7.24784 6.22648 6.93472 6.45312 6.61861 6.67529C6.56941 6.70959 6.52915 6.74835 6.49784 6.80054C6.42179 6.92728 6.30549 6.9735 6.16384 6.93324C6.01623 6.89149 5.96106 6.77519 5.96106 6.63503C5.95957 6.47996 6.0535 6.39646 6.19664 6.36515C6.25032 6.35322 6.29804 6.3398 6.34277 6.30849C6.88253 5.92977 7.42229 5.55253 7.96354 5.17678C8.01573 5.13951 8.04704 5.10223 8.04704 5.03364C8.04406 4.56545 8.04555 4.09577 8.04555 3.61416Z" fill="#FDFDFE"/>
			<path d="M8.04563 3.61426C8.04563 4.09587 8.04414 4.56555 8.04712 5.03374C8.04712 5.10233 8.01581 5.1411 7.96363 5.17688C7.42237 5.55263 6.88261 5.93136 6.34285 6.30859C6.29812 6.3399 6.24891 6.35332 6.19673 6.36525C6.05508 6.39656 5.96114 6.48006 5.96114 6.63513C5.96263 6.77678 6.01631 6.89309 6.16392 6.93334C6.30557 6.9736 6.42337 6.92589 6.49792 6.80064C6.52923 6.74696 6.56949 6.70969 6.6187 6.67539C6.9348 6.45472 7.24792 6.22658 7.56701 6.01038C8.54066 5.34835 9.77078 5.90153 9.9005 7.06903C9.96015 7.59686 9.7678 8.05312 9.33838 8.36476C8.41541 9.03424 7.47605 9.67987 6.54265 10.3359C6.5337 10.3419 6.52178 10.3419 6.48748 10.3523C6.48748 10.0228 6.5173 9.69627 6.48003 9.37718C6.43231 8.97311 6.57545 8.72559 6.92734 8.5243C7.33738 8.28722 7.71164 7.98901 8.1008 7.71615C8.18281 7.658 8.26333 7.60432 8.3677 7.58344C8.49891 7.5581 8.57198 7.46416 8.57048 7.32698C8.57048 7.18533 8.5183 7.07052 8.37068 7.02579C8.23351 6.98404 8.1172 7.03026 8.04563 7.14358C7.99792 7.21813 7.93828 7.26734 7.87118 7.31505C7.56551 7.52977 7.26432 7.75193 6.9512 7.95919C6.44275 8.29468 5.90597 8.33792 5.36919 8.04269C4.84881 7.75641 4.59981 7.29269 4.61174 6.70521C4.62068 6.24597 4.81303 5.8568 5.18728 5.58841C6.11472 4.92639 7.05408 4.27927 7.98897 3.62619C7.99792 3.62022 8.00985 3.62171 8.04563 3.61426Z" fill="#303033"/>
		</svg>

		<svg v-if="code ==='destination-sm'" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.31672 17.9961C7.30479 17.9961 7.29286 17.9961 7.28094 17.9961C7.25559 17.917 7.18253 17.8813 7.12736 17.8291C5.86742 16.6571 4.69098 15.4046 3.61742 14.0597C2.58263 12.7669 1.65072 11.4071 0.981234 9.88176C0.422089 8.6084 0.168609 7.29478 0.399723 5.90363C0.957377 2.53534 3.88432 0.0542254 7.31821 0.0527344C7.31821 0.936929 7.31821 1.82112 7.31821 2.70532C7.11096 2.71427 6.9037 2.71874 6.69644 2.74707C3.84107 3.13474 2.12785 6.21675 3.34903 8.83504C4.12587 10.4991 5.48123 11.3415 7.31821 11.3997C7.31821 13.5885 7.31821 15.7759 7.31821 17.9648C7.31523 17.9752 7.31523 17.9856 7.31672 17.9961Z" fill="#00E678"/>
			<path d="M7.31836 2.7036C7.31836 1.8194 7.31836 0.935209 7.31836 0.0510139C8.25474 0.0435587 9.15981 0.21503 10.0127 0.59674C12.3358 1.636 13.7418 3.40887 14.21 5.9198C14.2488 6.12557 14.2354 6.33879 14.3099 6.5371C14.3099 6.6206 14.3099 6.7041 14.3099 6.78759C14.2503 6.95459 14.2518 7.1201 14.3099 7.2871C14.3099 7.35867 14.3099 7.43024 14.3099 7.50181C14.2726 7.51225 14.2756 7.54356 14.2741 7.57338C14.2547 7.83431 14.2055 8.08928 14.1459 8.34276C13.8596 9.57437 13.302 10.6867 12.6414 11.7513C11.5052 13.5823 10.1111 15.2076 8.60812 16.7404C8.19361 17.1638 7.77909 17.5903 7.31836 17.9645C7.31836 15.7757 7.31836 13.5883 7.31836 11.3994C7.5614 11.389 7.80295 11.3696 8.04301 11.3279C10.3363 10.9312 11.9063 8.80946 11.6036 6.49982C11.4292 5.16831 10.7642 4.1365 9.65037 3.39843C8.94659 2.93173 8.1608 2.71851 7.31836 2.7036Z" fill="#00D06A"/>
			<path d="M14.3098 7.28759C14.2516 7.12059 14.2502 6.95508 14.3098 6.78809C14.3098 6.95359 14.3098 7.12059 14.3098 7.28759Z" fill="#47484F"/>
			<path d="M7.31792 2.70312C8.16036 2.71804 8.94764 2.93126 9.65141 3.39796C10.7652 4.13603 11.4302 5.16784 11.6047 6.49935C11.9059 8.80899 10.3373 10.9308 8.04406 11.3274C7.804 11.3691 7.56245 11.3885 7.31941 11.3989C5.48243 11.3408 4.12557 10.4984 3.35022 8.83434C2.12905 6.21605 3.84227 3.13404 6.69913 2.74487C6.9034 2.71654 7.11066 2.71207 7.31792 2.70312ZM8.04555 3.61416C8.00976 3.62161 7.99784 3.62012 7.98889 3.62609C7.054 4.27917 6.11464 4.92479 5.1872 5.58831C4.81295 5.85521 4.6206 6.24587 4.61165 6.70511C4.59973 7.29408 4.84873 7.75631 5.36911 8.04259C5.90589 8.33782 6.44416 8.29458 6.95112 7.95909C7.26275 7.75332 7.56394 7.53116 7.8711 7.31495C7.93819 7.26724 7.99784 7.21804 8.04555 7.14348C8.11712 7.03016 8.23491 6.98394 8.3706 7.02569C8.51821 7.07042 8.5704 7.18523 8.5704 7.32688C8.5704 7.46257 8.49883 7.5565 8.36762 7.58334C8.26324 7.60422 8.18273 7.6579 8.10072 7.71605C7.71155 7.98891 7.3373 8.28861 6.92726 8.5242C6.57537 8.72698 6.43372 8.97301 6.47994 9.37708C6.51722 9.69617 6.4874 10.0227 6.4874 10.3522C6.52169 10.3418 6.53362 10.3418 6.54257 10.3358C7.47597 9.68126 8.41533 9.03414 9.33829 8.36466C9.76772 8.05303 9.96006 7.59527 9.90042 7.06893C9.76921 5.90143 8.54058 5.34975 7.56692 6.01028C7.24784 6.22648 6.93472 6.45312 6.61861 6.67529C6.56941 6.70959 6.52915 6.74835 6.49784 6.80054C6.42179 6.92728 6.30549 6.9735 6.16384 6.93324C6.01623 6.89149 5.96106 6.77519 5.96106 6.63503C5.95957 6.47996 6.0535 6.39646 6.19664 6.36515C6.25032 6.35322 6.29804 6.3398 6.34277 6.30849C6.88253 5.92977 7.42229 5.55253 7.96354 5.17678C8.01573 5.13951 8.04704 5.10223 8.04704 5.03364C8.04406 4.56545 8.04555 4.09577 8.04555 3.61416Z" fill="#FDFDFE"/>
			<path d="M8.04563 3.61426C8.04563 4.09587 8.04414 4.56555 8.04712 5.03374C8.04712 5.10233 8.01581 5.1411 7.96363 5.17688C7.42237 5.55263 6.88261 5.93136 6.34285 6.30859C6.29812 6.3399 6.24891 6.35332 6.19673 6.36525C6.05508 6.39656 5.96114 6.48006 5.96114 6.63513C5.96263 6.77678 6.01631 6.89309 6.16392 6.93334C6.30557 6.9736 6.42337 6.92589 6.49792 6.80064C6.52923 6.74696 6.56949 6.70969 6.6187 6.67539C6.9348 6.45472 7.24792 6.22658 7.56701 6.01038C8.54066 5.34835 9.77078 5.90153 9.9005 7.06903C9.96015 7.59686 9.7678 8.05312 9.33838 8.36476C8.41541 9.03424 7.47605 9.67987 6.54265 10.3359C6.5337 10.3419 6.52178 10.3419 6.48748 10.3523C6.48748 10.0228 6.5173 9.69627 6.48003 9.37718C6.43231 8.97311 6.57545 8.72559 6.92734 8.5243C7.33738 8.28722 7.71164 7.98901 8.1008 7.71615C8.18281 7.658 8.26333 7.60432 8.3677 7.58344C8.49891 7.5581 8.57198 7.46416 8.57048 7.32698C8.57048 7.18533 8.5183 7.07052 8.37068 7.02579C8.23351 6.98404 8.1172 7.03026 8.04563 7.14358C7.99792 7.21813 7.93828 7.26734 7.87118 7.31505C7.56551 7.52977 7.26432 7.75193 6.9512 7.95919C6.44275 8.29468 5.90597 8.33792 5.36919 8.04269C4.84881 7.75641 4.59981 7.29269 4.61174 6.70521C4.62068 6.24597 4.81303 5.8568 5.18728 5.58841C6.11472 4.92639 7.05408 4.27927 7.98897 3.62619C7.99792 3.62022 8.00985 3.62171 8.04563 3.61426Z" fill="#00E677"/>
		</svg>

		<svg v-if="code ==='captain'" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8 0.117188C3.58169 0.117188 0 3.06571 0 5.76425C0 7.11648 0.899529 7.82213 2.35294 8.1897V9.52895C2.35294 10.9407 5.64706 11.8819 8 11.8819C10.3529 11.8819 13.6471 10.9407 13.6471 9.52895V8.1897C15.1005 7.82213 16 7.11648 16 5.76425C16 3.06571 12.4183 0.117188 8 0.117188ZM6.58824 1.99954H9.41177V3.88189C9.41177 4.66156 8 5.29366 8 5.29366C8 5.29366 6.58824 4.66156 6.58824 3.88189V1.99954ZM13.6471 7.6466H2.35294V6.70542H13.6471V7.6466Z" fill="#4848ED"/>
		</svg>
	</span>
</template>

<script>
export default {
  name: 'svg-template',
  props: {
    code: { type: String, required: true }
  }
}
</script>

<style scoped>

</style>
