<template>
	<div class="user-picker">
		<div class="card shadow-none">
			<b-table striped hover selectable show-empty responsive :items="selectedUsersValues" :fields="fields"
				:current-page="currentPage">
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>

				<template #empty>
					<p class="text-center text-secondary">
						{{
							search
								? `No records found for search value: "${search}"`
								: "No records available"
						}}
					</p>
				</template>

				<template #cell(avatar)="data">
					<user-avatar :user="data.item" size="sm"></user-avatar>
				</template>

				<template #cell(user)="data">
					<div>
						<span>{{ data.item.fname }}</span> <span>{{ data.item.lname }}</span>
					</div>
					<div>
						<span>{{ data.item.email }}</span>
					</div>
				</template>

				<template #cell(pickup_id)="data">
					<div>
						<select v-model="data.item.pickup_route_bus_stop_id" :name="`customer_pickup_id_${data.item.id}`"
							:id="`customer_pickup_id_${data.item.id}`">
							<option :key="option.id" :value="option.id" v-for="option in routeBusStops">
								{{ option.name }}
							</option>
						</select>
					</div>
				</template>

				<template #cell(destination_id)="data">
					<div>
						<select v-model="data.item.destination_route_bus_stop_id" :name="`customer_destination_id_${data.item.id}`"
							:id="`customer_destination_id_${data.item.id}`">
							<option :key="option.id" :value="option.id" v-for="option in routeBusStops">
								{{ option.name }}
							</option>
						</select>
					</div>
				</template>

				<template #cell(action)="data">
					<div>
						<button class="btn btn-sm" @click="removePassenger(data.item.id)">
							Remove
						</button>
					</div>
				</template>
			</b-table>

			<div class="card-footer">
				<div>
					<div class="row">
						<div class="col">
							<div class="float-right">
								<button class="btn btn-outline-primary mr-2" @click="cancelUserPicker">
									Cancel
								</button>
								<button class="btn btn-primary" @click="addSelectedUserPicker">
									Proceed
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import moment from 'moment'
export default {
  components: {
    UserAvatar
  },
  props: {
    selectedUsers: {
      type: [Array],
      default: () => []
    },
    routeBusStops: {
      type: [Array],
      default: () => []
    }
  },
  data() {
    return {
      selectedUsersValues: [],
      fields: [
        {
          key: 'avatar',
          label: 'Avatar'
        },
        {
          key: 'user',
          label: 'User'
        },

        {
          key: 'pickup_id',
          label: 'Pickup Bus stop'
        },
        {
          key: 'destination_id',
          label: 'Dropoff Bus Stop'
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      users: [],
      fetchingUsers: true,
      downloadingReport: false,
      search: '',
      filter: {
        range: []
      },
      statusFilter: 'active'
    }
  },
  mounted() {
    this.selectedUsersValues = this.selectedUsers
  },
  computed: {
    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid() {
      return (
        this.filter.range.length && this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {},
  methods: {
    removePassenger(id) {
      const index = this.selectedUsersValues.findIndex((i) => i.id == id)
      if (index > -1) {
        this.selectedUsersValues.splice(index, 1)
      }
    },
    cancelUserPicker() {
      this.$emit('cancel')
    },
    addSelectedUserPicker() {
      this.$emit('proceed', Object.values(this.selectedUsersValues))
    }
  }
}
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
