import 'core-js/actual/array/group-by'
export default class Manifest {
  constructor (manifest) {
    this.manifest = manifest
  }

  groupSortedPickupManifestByPickupName () {
    const manifestSortedByPickupPosition = [...this.manifest]

    manifestSortedByPickupPosition.sort(function (a, b) {
      return a.pickupRouteBusStop.position - b.pickupRouteBusStop.position
    })
    const response = manifestSortedByPickupPosition.groupBy((user) => {
      return user.pickupRouteBusStop.name
    })
    // return response
    return response
  }

  groupSortedDestinationManifestByDestinationName () {
    const manifestSortedByDestinationPositionAndReversed = [...this.manifest]

    manifestSortedByDestinationPositionAndReversed.sort(function (a, b) {
      return a.destinationRouteBusStop.position - b.destinationRouteBusStop.position
    }).reverse()
    // const ps = manifestSortedByDestinationPositionAndReversed.map(pass => pass.destinationRouteBusStop.position)
    const response = manifestSortedByDestinationPositionAndReversed.groupBy((user) => {
      return user.destinationRouteBusStop.name
    })
    // return response

    return response
  }
}
