<template>
	<div class="user-picker">
		<div class="card shadow-none">
			<div class="card-header justify-content-between">
				<div class="input-group input-group-flush d-flex flex-row-reverse">
					<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search"
						@keyup.enter.prevent="fetchUsers(true)" />
					<span class="input-group-text border-0">
						<i class="fe fe-search pointer" @click.prevent="fetchUsers(true)"></i>
					</span>
				</div>
			</div>

			<b-table striped hover selectable show-empty responsive :items="users" :fields="fields" :current-page="currentPage"
				:busy="fetchingUsers">
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>

				<template #empty>
					<p class="text-center text-secondary">
						{{
							search
								? `No records found for search value: "${search}"`
								: "No records available"
						}}
					</p>
				</template>

				<template #cell(created_at)="data">
					{{ data.value | date("dd/MM/yyyy") }}
				</template>

				<template #cell(fname)="data">
					{{ data.value }}
				</template>

				<template #cell(lname)="data">
					{{ data.value }}
				</template>

				<template #cell(email)="data">
					{{ data.value }}
				</template>

				<template #cell(phone)="data">
					{{ data.value }}
				</template>

				<template #cell(avatar)="data">
					<user-avatar :user="data.item" size="sm"></user-avatar>
				</template>

				<template #cell(user)="data">
					<div>
						<input type="checkbox" :name="`select-user-${data.item.id}`" :id="`select-user-${data.item.id}`"
							:checked="!!selectedUsers[data.item.id]" @change="toggleUserSection(data.item.id, data.item)" />
					</div>
				</template>
			</b-table>

			<div class="card-footer" v-if="totalRecords">
				<div>
					<div class="row">
						<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
					</div>
					<div class="row">
						<div class="col">
							<div class=" float-right">
								<button class="btn btn-outline-primary mr-2" @click="cancelUserPicker">Cancel</button>
								<button class="btn btn-primary" @click="addSelectedUserPicker">
									Add
									{{
										selectedUsersValues && selectedUsersValues.length > 0
											? `${selectedUsersValues?.length} selected users`
											: ""
									}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import moment from 'moment'
export default {
  components: {
    UserAvatar
  },
  data() {
    return {
      selectedUsers: {},
      fields: [
        {
          key: 'user',
          label: 'Select'
        },
        {
          key: 'avatar',
          label: 'Avatar'
        },
        {
          key: 'fname',
          label: 'First name'
        },
        {
          key: 'lname',
          label: 'Last name'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'phone',
          label: 'Phone'
        },
        {
          key: 'created_at',
          label: 'Date Joined'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      users: [],
      fetchingUsers: true,
      downloadingReport: false,
      search: '',
      filter: {
        range: []
      },
      statusFilter: 'active'
    }
  },
  created() {
    this.fetchUsers()
  },
  computed: {
    selectedUsersValues() {
      return Object.values(this.selectedUsers)
    },
    filteredUsers() {
      const search = this.search.toLowerCase()
      let users = this.users.filter((user) => {
        return (
          user.fname.toLowerCase().includes(search) ||
          user.lname.toLowerCase().includes(search) ||
          user.email.toLowerCase().includes(search) ||
          user.phone.toLowerCase().includes(search)
        )
      })
      if (this.dateFilterValid) {
        const dateRange = this.filter.range.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )
        users = users.filter(
          (user) =>
            user.created_at.split(' ')[0] >= dateRange[0] &&
            user.created_at.split(' ')[0] <= dateRange[1]
        )
      }
      return users
    },
    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid() {
      return (
        this.filter.range.length && this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    currentPage() {
      this.fetchUsers()
    },
    search(value) {
      if (!value) {
        this.fetchUsers(true)
      }
    },
    dateFilterValid() {
      this.fetchUsers(true)
    },
    dateRange(newValue, oldValue) {
      if (newValue[0] !== newValue[1] || oldValue[0] !== oldValue[1]) {
        this.fetchUsers(true)
      }
    },
    statusFilter() {
      this.fetchUsers(true)
    }
  },
  methods: {
    cancelUserPicker() {
      this.$emit('cancel')
    },
    addSelectedUserPicker() {
      this.$emit('selected', Object.values(this.selectedUsers))
    },
    toggleUserSection(userId, item) {
      if (this.selectedUsers[userId]) {
        delete this.selectedUsers[userId]
        this.selectedUsers = { ...this.selectedUsers }
        return
      }
      this.selectedUsers = { ...this.selectedUsers, [userId]: item }
    },
    fetchUsers(reset = false) {
      if (reset) {
        this.currentPage = 1
      }
      this.fetchingUsers = true
      const dateRange = this.filter.range.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
      const url = `/v1/users${this.search ? '/search' : ''}?limit=${this.perPage}&page=${this.currentPage
        }&metadata=true&sort[id]=desc&related=wallet&status=${this.statusFilter}`
      const filter_url = `/v1/users?metadata=true&page=${this.currentPage}&limit=${this.perPage}&start_date_filter=${dateRange[0]}&end_date_filter=${dateRange[1]}`
      const request = this.search
        ? this.axios.post(url, {
          user: this.search || '',
          creation_date_start: this.dateFilterValid ? dateRange[0] : null,
          creation_date_end: this.dateFilterValid ? dateRange[1] : null
        })
        : this.dateFilterValid
          ? this.axios.get(filter_url)
          : this.axios.get(url)
      request
        .then((res) => {
          this.users = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.users
        })
        .finally(() => (this.fetchingUsers = false))
    }
  }
}
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
